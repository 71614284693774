.list {
    @apply grid-cols-1;

    display: grid;
    gap: theme('rhythm.xsmall');

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-2;

        column-gap: theme('spacing.150');
        row-gap: theme('rhythm.xsmall');
    }

    @media (min-width: theme('screens.lg')) {
        @apply grid-cols-4;

        gap: theme('spacing.150');
    }
}
