.listItem {
    position: relative;
    padding-top: 8.375rem;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px 8px 128px 128px;
}

.card {
    height: 100%;
    padding: 8.625rem theme('spacing.100') theme('spacing.100');
}

.link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
}
