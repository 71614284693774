.variantDark {
    background: theme('colors.layers.soft');
    --border-color: theme('colors.decorators.muted');
    --shield-color: theme('colors.neutrals.white');

    h2,
    h5 {
        color: theme('colors.layers.highlight');
    }

    p {
        color: theme('colors.neutrals.white');
    }
}

.variantLight {
    border: 1px solid theme('colors.decorators.accent');
    padding: theme('spacing.300');
    background: theme('colors.neutrals.white');
    --border-color: theme('colors.decorators.subtle');
    --shield-color: theme('colors.layers.canvas');
}

.wrapper {
    padding: theme('spacing.050');
    border-radius: theme('borderRadius.200');

    @media (min-width: 465px) {
        padding: theme('spacing.100');
    }

    @media (min-width: 960px) {
        padding: theme('spacing.300');
    }
}

.container {
    padding: theme('spacing.200') 0;
    border: 1px solid var(--border-color, theme('colors.decorators.muted'));
    border-radius: inherit;

    @media (min-width: 465px) {
        padding: theme('spacing.300') 0;
    }

    h2 {
        padding: 0 theme('container.gutters.small');
        text-align: center;

        @media (min-width: 465px) {
            padding: 0 theme('container.gutters.medium');
        }
    }
}

.list {
    max-width: theme('container.sizes.xsmall');
    margin: theme('rhythm.small') auto 0;
    padding: 0 theme('container.gutters.medium');
    text-align: center;

    @media (min-width: 465px) and (max-width: 760px) {
        text-align: left;
    }

    @media (min-width: 760px) {
        display: flex;
        justify-content: space-between;
        max-width: theme('container.sizes.medium');
    }
}

.listItem {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.100');

    @media (min-width: 465px) and (max-width: 759px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: theme('spacing.150');
    }
}

.listItemArrow {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    height: 2rem;

    @media (min-width: 760px) {
        width: theme('spacing.300');
        height: auto;
    }
}

.mobileArrow {
    display: none;

    @media (min-width: 465px) and (max-width: 759px) {
        position: absolute;
        top: -0.35rem;
        left: theme('spacing.200');
        display: block;

        &.mobileArrowLeft {
            left: 0;
        }
    }
}

.arrowPosition {
    position: absolute;
    top: 0;
    left: 50%;
    max-width: none;
    transform: translateX(-51%);

    &.arrowPositionLowered {
        top: theme('spacing.100');
    }
}

.tabletArrow {
    display: none;

    @media (min-width: 760px) and (max-width: 959px) {
        display: block;
    }
}

.desktopArrow {
    display: none;

    @media (min-width: 960px) {
        display: block;
        max-width: 12rem;
    }

    @media (min-width: 1000px) {
        max-width: none;
    }
}

.shield {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    padding: theme('spacing.075');
    margin: 0 auto;
    box-sizing: content-box;
    border-radius: 2px 2px 40px 40px;
    background: var(--shield-color, theme('colors.neutrals.white'));
    overflow: visible;

    @media (min-width: 465px) and (max-width: 759px) {
        margin: 0;
    }
}
