.page_wrapper__O9G62 {
    position: relative;
    min-height: 50rem;
}

    @media screen and (min-width: 768px) {.page_wrapper__O9G62 {
        display: flex
}
    }

.Flash_flash__2EAUp {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    padding: 8px 16px;
    border-radius: 12px;
}

.Flash_tight__hIcgH {
    justify-content: center;
}

.Flash_info__3HbTW {
    background: #cfdcf3;
}

.Flash_info__3HbTW svg {
        fill: #1a548a;
    }

.Flash_warning__G0c__ {
    background: #fff2d0;
}

.Flash_warning__G0c__ svg {
        fill: #776944;
    }

.Flash_error__kUO6n {
    background: #ffebee;
}

.Flash_error__kUO6n svg {
        fill: #951b46;
    }

.Flash_success__7BWCX {
    background: #e6fcf4;
}

.Flash_success__7BWCX svg {
        fill: #468772;
    }

.FlashMessage_flashMessage__4N4Bz {
    position: fixed;
    bottom: 4.25rem; /* Places it above the draw nav component. */
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 16px;
    z-index: 1300;
}

    @media screen and (min-width: 768px) {.FlashMessage_flashMessage__4N4Bz {
        bottom: 32px
}
    }

.AccountTreatmentList_item__wosjd {

    display: flex;

    align-items: center;

    justify-content: flex-start;

    -moz-column-gap: 8px;

         column-gap: 8px
}

.AccountTreatmentList_image__sn60J {

    height: 32px;

    width: 32px
}

.AccountTreatmentList_limit__GmQQ4 {

    display: flex;

    height: 32px;

    width: 32px;

    flex-direction: row;

    align-items: center;

    justify-content: center;

    border: 1px solid #CAD8E2;
    border-radius: 6px
}
.AccountSavedBasketsPreviewItem_savedBasketCard__uxUYD {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF
}

.ProgressCounter_counter__XT2Q_ {

    font-size: 11.237px;

    line-height: 150%;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding-bottom: 2px;
    border-radius: 100%;
    background-color: #303C69;
    color: #FFFFFF;
}

.ProgressCounter_counterActive__llPJe {
    background-color: #007ACC;
}

.CheckoutProgress_wrapper__xmOvs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    max-width: 21.25rem;
    margin: 0 auto;
}

.CheckoutProgress_separator__sTkWv {
    height: 2px;
    flex-grow: 1;
    background: #CAD8E2;
}

.CheckoutProgress_separatorActive__poGtx {
    background: #5A5E6E;
}

/*
 * Background set to important as button-reset was un-setting the background-color classes and in certain
 * states was making the button transparent in certain conditions.
 */

.CheckoutSummaryMobileButton_button__t6m_Q {

    border: 1px solid transparent;

    background: #FFFFFF;

    border: 1px solid #CAD8E2;

    box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;

    position: sticky;
    top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-color: #FFFFFF !important;
    border-radius: 12px;
    padding: 16px 8px;
    z-index: 600;
    box-sizing: border-box
}

@media screen and (min-width: 768px) {

.CheckoutSummaryMobileButton_button__t6m_Q {
        display: none
}
    }

.CheckoutPaymentCard_logo__vk_YO {
    display: flex;
    align-items: center;
    border: 1px solid #CAD8E2;
    border-radius: 4px;
    overflow: hidden;
}

.ApplePayButton_button__rGsvF {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.75rem;
    border: 2px solid black;
    border-radius: 1000px;
    background-color: #151515 !important;
    box-sizing: content-box;
}

    @media screen and (min-width: 640px) {.ApplePayButton_button__rGsvF {
        width: 12.5rem
}
    }

.ApplePayButton_buttonBuy__QNBUU {
    -webkit-appearance: -apple-pay-button !important;
       -moz-appearance: -apple-pay-button !important;
            appearance: -apple-pay-button !important;
    -apple-pay-button-type: buy;
    -apple-pay-button-style: black;
}

.ApplePayButton_buttonLoading__8FO6p .busy-icon span {
            background: #FFF;
        }

.GooglePayButton_disabled__58Tsz {
    opacity: 0.5;
    cursor: not-allowed;
}

    .GooglePayButton_disabled__58Tsz > * {
        pointer-events: none;
    }

.CheckoutError_message__woaRI {
        font-size: 14.222px;
        line-height: 150%;
}

.CheckoutError_message__woaRI > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

    .CheckoutError_message__woaRI h3 {
        font-family: fields, arial, system-ui, serif;
        font-weight: 600;
        font-size: 14.222px;
        line-height: 150%;
}

    .CheckoutError_message__woaRI ul {
        list-style: disc;
        padding-left: 24px;
    }

    .CheckoutError_message__woaRI .flow--small > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

/* --- Sizes --- */

.BaseIcon_xxsmall__sEEWi {
    width: 8px;
    height: 8px;
}

.BaseIcon_xsmall__d5BNr {
    width: 12px;
    height: 12px;
}

.BaseIcon_small___YLsI {
    width: 16px;
    height: 16px;
}

.BaseIcon_medium__mS36i {
    width: 24px;
    height: 24px;
}

.BaseIcon_large__vfcSA {
    width: 32px;
    height: 32px;
}

.BaseIcon_xlarge__N7K9N {
    width: 48px;
    height: 48px;
}

@media (min-width: 768px) {

.AccountPersonalMedicalRecord_subTitle__CW2hN {
font-size: 28.833px;
line-height: 124%;
}
}

@media (min-width: 1024px) {

.AccountPersonalMedicalRecord_subTitle__CW2hN {
font-size: 32.437px;
line-height: 120%;
}
}

.AccountPersonalMedicalRecord_pmrGrid__6ZZcT {
display: grid;
gap: 24px;
}

@media (min-width: 768px) {

.AccountPersonalMedicalRecord_pmrGrid__6ZZcT {
grid-template-columns: repeat(2, minmax(0, 1fr));
}
    }

.AccountPersonalMedicalRecord_pmrGridSupport__5AKXa {
    grid-row: span 2;
    padding-top: 0;

}

@media (min-width: 768px) {

.AccountPersonalMedicalRecord_pmrGridSupport__5AKXa {
        grid-column: span 2

}
    }

.ErrorPanel_logos__f7SDD {
    display: flex;
    gap: 8px;
    margin-left: auto;
}

    .ErrorPanel_logos__f7SDD img {
        height: 1.5rem;
        border: 1px solid #CAD8E2;
        border-radius: 6px;
        flex-shrink: 1;
    }

.ErrorPanel_message__Jcci7 {
    font-size: 14.222px;
    line-height: 150%;
}

.ErrorPanel_message__Jcci7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

.ErrorPanel_message__Jcci7 {

    margin-top: 16px;
}

.ErrorPanel_message__Jcci7 h3 {
    font-family: fields, arial, system-ui, serif;
    font-weight: 600;
    font-size: 14.222px;
    line-height: 150%;
}

.ErrorPanel_message__Jcci7 ul {
        list-style: disc;
        padding-left: 24px;
    }

.ErrorPanel_message__Jcci7 .flow--small > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

.BasketBlockers_confirmationButtons__kfNUU {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
}

    @media (min-width: 1024px) {.BasketBlockers_confirmationButtons__kfNUU {
        flex-direction: row-reverse;
        gap: 8px
}
    }

    .BasketBlockers_confirmationButtons__kfNUU a {
    color: #007ACC;
    text-decoration: none;
}

    .BasketBlockers_confirmationButtons__kfNUU a:hover,.BasketBlockers_confirmationButtons__kfNUU a:focus {
    text-decoration: underline;
}

    .BasketBlockers_confirmationButtons__kfNUU a {

        padding: 20px 48px;
        text-align: center;
    }

.SummaryItems_listItem__fraSY {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 0;
    border-bottom: 1px dashed #CAD8E2;
}

.SummaryItems_img__klq4n {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    padding: 4px;
    border-radius: 12px;
    background: #FFFFFF;
    -o-object-fit: contain;
       object-fit: contain;
    box-sizing: content-box;
}

.CheckoutSummary_wrapper__xgmOe > div {
        padding: 12px 0;
        border-bottom: 1px solid #CAD8E2;
    }

        .CheckoutSummary_wrapper__xgmOe > div:last-of-type {
            border-bottom: none;
            padding-top: 16px;
            padding-bottom: 16px;
        }

.CheckoutSummary_flyout__1rR3S {
    padding-top: 80px;
}

.CheckoutSummary_flyout__1rR3S aside {
        border-radius: 12px 12px 0 0;
    }

.CheckoutSummary_flyoutContent__iqBYF ul {
        padding-left: 16px;
        padding-right: 16px;
    }

.CheckoutSummary_flyoutContent__iqBYF > div {
        padding-left: 16px;
        padding-right: 16px;
    }

.ErrorPanel_panel__q2iS8 {
    align-self: center;
    max-width: 480px;
    padding: 0 16px;
    margin: 96px auto;
    text-align: center;
}

    @media (min-width: 768px) {.ErrorPanel_panel__q2iS8 {
        padding: 0 24px
}
    }

.Header_header__qegNC {
    position: relative;
    background-color: #303C69;
    padding: 16px;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: #303C69;
    border-radius: 0 0 24px 24px;
    transition: 300ms border-color cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 500;
}

.Header_logo__E8WRn {
    flex-shrink: 0;
    border-radius: 6px;
}

.Header_logo__E8WRn:focus {
        outline: 2px solid #007ACC;
        outline-offset: 2px;
    }

.Header_logo__E8WRn img {
        width: 7.75rem;
    }

.Header_search__7pkI4 {
    display: none;
}

@media (min-width: 1024px) {

.Header_search__7pkI4 {
        margin-right: -16px;

        display: block;
        flex-grow: 0;
        width: 15rem;
        transition: flex-grow 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    }

@media (min-width: 1280px) {

.Header_search__7pkI4 {
        width: 19.75rem
}
    }

.Header_search__7pkI4:focus-within {
        flex-grow: 1;
    }

.Header_links__HGgTA {
    display: flex;
    flex-shrink: 0;
    margin-left: auto;
}

.Header_link__y5K3N {
    gap: 8px;
    padding: 12px 16px;
    border-radius: 6px;
    background: transparent;
    transition: 600ms background cubic-bezier(0.23, 1, 0.32, 1);
    font-family: sofia-pro, arial, sans-serif;
    font-weight: 700;
}

.Header_link__y5K3N:focus {
        background: #303c6940;
        outline: 2px solid #007ACC;
    }

.Wysiwyg_wysiwyg__lML8w > * + * {
        margin-top: 1em;
    }
    /* --- Table --- */
    .Wysiwyg_wysiwyg__lML8w .table-wrapper {
        border: 1px solid #CAD8E2;

        border-radius: 12px;
        overflow-x: auto;
        scrollbar-color: #31CC9C #FFFFFF;
    }
    .Wysiwyg_wysiwyg__lML8w .table-wrapper::-webkit-scrollbar {
            height: 0.5rem;
        }
    .Wysiwyg_wysiwyg__lML8w .table-wrapper::-webkit-scrollbar-thumb {
            border-radius: 0 0 0.25rem 0.25rem;
            background-color: #31CC9C;
        }
    .Wysiwyg_wysiwyg__lML8w .table-wrapper::-webkit-scrollbar-track {
            border-radius: 0 0 0.25rem 0.25rem;
            background-color: #FFFFFF;
        }
    .Wysiwyg_wysiwyg__lML8w .table-wrapper table {
            min-width: 100%;
            width: auto;
        }
    .Wysiwyg_wysiwyg__lML8w .table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        border-radius: 12px;
        background-color: #FFFFFF;
        font-size: 14.222px;
        text-align: left;
    }
    .Wysiwyg_wysiwyg__lML8w .table thead {
            border-bottom: 1px solid #CAD8E2;
            font-weight: 500;
            background-color: #303C69;
        }
    .Wysiwyg_wysiwyg__lML8w .table th {
            white-space: nowrap;
            padding: 16px;
            background-color: #303C69;
            color: #FFFFFF;
        }
    .Wysiwyg_wysiwyg__lML8w .table th + th {
                border-left: 1px solid #303C69;
            }
    .Wysiwyg_wysiwyg__lML8w .table tr + tr {
            border-top: 1px solid #CAD8E2;
        }
    .Wysiwyg_wysiwyg__lML8w .table td {
            padding: 12px;
            vertical-align: top;
            white-space: nowrap;
        }
    .Wysiwyg_wysiwyg__lML8w .table td p {
                white-space: initial;
                min-width: 20rem;
            }
    .Wysiwyg_wysiwyg__lML8w .table td + td {
                border-left: 1px solid #CAD8E2;
            }
    .Wysiwyg_wysiwyg__lML8w .table td:last-child {
                padding-right: 32px;
            }
    .Wysiwyg_wysiwyg__lML8w .table figure {
            margin: 0;
        }
    .Wysiwyg_wysiwyg__lML8w .table .Wysiwyg_button__soV10:only-child {
            width: 100%;
        }
    .Wysiwyg_wysiwyg__lML8w .table .Wysiwyg_trustpilot-widget__Bv_x1 {
            min-width: 10.75rem;
            padding-top: 4px;
            pointer-events: none;
        }
    .Wysiwyg_wysiwyg__lML8w .table-minimal {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        text-align: left;
    }
    .Wysiwyg_wysiwyg__lML8w .table-minimal tbody tr + tr {
            border-top: 1px solid #CAD8E2;
        }
    .Wysiwyg_wysiwyg__lML8w .table-minimal td {
            width: 50%;
        }
    .Wysiwyg_wysiwyg__lML8w .table-minimal td + td {
            border-left: 1px solid #CAD8E2;
        }
    .Wysiwyg_wysiwyg__lML8w .table-minimal tbody td {
            padding: 16px 0;
        }
    .Wysiwyg_wysiwyg__lML8w .table-minimal tbody td:last-child {
                padding-left: 16px;
            }
    /* --- Elements --- */
    .Wysiwyg_wysiwyg__lML8w small {
        font-size: 11.237px;
        line-height: 150%;
}
    .Wysiwyg_wysiwyg__lML8w img {
        max-width: 100%;
    }
    .Wysiwyg_wysiwyg__lML8w ul > :not([hidden]) ~ :not([hidden]),
    .Wysiwyg_wysiwyg__lML8w ol > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(8px * var(--tw-space-y-reverse));
}
    .Wysiwyg_wysiwyg__lML8w ul,
    .Wysiwyg_wysiwyg__lML8w ol {
        padding: 16px 0 16px 16px;
        list-style: none;
    }
    .Wysiwyg_wysiwyg__lML8w ol {
        counter-reset: order;
    }
    .Wysiwyg_wysiwyg__lML8w li {
        padding-left: 24px;
        position: relative;
    }
    .Wysiwyg_wysiwyg__lML8w li::before {
        position: absolute;
        left: 0;
    }
    .Wysiwyg_wysiwyg__lML8w ol li {
        counter-increment: order;
    }
    .Wysiwyg_wysiwyg__lML8w ol li::before {
        content: counter(order) '.';
        color: #303C69;
    }
    .Wysiwyg_wysiwyg__lML8w ul li::before {
        content: '';
        width: 8px;
        height: 8px;
        background: #303C69;
        border-radius: 50%;
        top: calc((calc(16px * 1.6) - 8px) / 2);
    }
    .Wysiwyg_wysiwyg__lML8w .table-wrapper {
        margin: 32px 0;
    }
    .Wysiwyg_wysiwyg__lML8w figure {
        margin: 32px 0;
    }
    .Wysiwyg_wysiwyg__lML8w figure:first-child {
            margin-top: 0;
        }
    .Wysiwyg_wysiwyg__lML8w figure.Wysiwyg_outgrow__oWuL4 > img {
        position: relative;
        max-width: none;
        width: calc(100% + 32px);
        left: 50%;
        border-radius: 0.25rem;
        transform: translateX(-50%);
        -o-object-fit: cover;
           object-fit: cover;
        overflow: hidden;
    }
    .Wysiwyg_wysiwyg__lML8w .card {
        display: flex;
        gap: 16px;
        padding: 12px;
    }
    .Wysiwyg_wysiwyg__lML8w .card img {
            max-width: 4rem;
        }
    .Wysiwyg_wysiwyg__lML8w .card .text-title-six {
        font-size: 1em;
        line-height: 150%;
}
    /* --- Utilities --- */
    .Wysiwyg_wysiwyg__lML8w .breathe--large > * + * {
            margin-left: 16px;
        }
    .Wysiwyg_wysiwyg__lML8w .flow > * + * {
            margin-top: 8px;
        }
    .Wysiwyg_wysiwyg__lML8w .display--block {
        display: block;
    }
    .Wysiwyg_wysiwyg__lML8w .flex {
        display: flex;
    }
    .Wysiwyg_wysiwyg__lML8w .flex--row {
        flex-direction: row;
    }
    .Wysiwyg_wysiwyg__lML8w .flex--space-between {
        justify-content: space-between;
    }
    .Wysiwyg_wysiwyg__lML8w .flex--align-center {
        align-items: center;
    }
    .Wysiwyg_wysiwyg__lML8w .flex--wrap {
        flex-wrap: wrap;
    }
    /* --- Headings --- */
    .Wysiwyg_wysiwyg__lML8w h1 {
        font-family: fields, arial, system-ui, serif;
        font-weight: 400;
        font-size: 36.491px;
        font-size: 1.8020625em;
        line-height: 128%;
}
    .Wysiwyg_wysiwyg__lML8w h2 {
        font-family: fields, arial, system-ui, serif;
        font-weight: 400;
        font-size: 36.491px;
        font-size: 1.6018125em;
        line-height: 132%;
}
    .Wysiwyg_wysiwyg__lML8w h3 {
        font-family: fields, arial, system-ui, serif;
        font-weight: 400;
        font-size: 36.491px;
        font-size: 1.4238125em;
        line-height: 136%;
}
    .Wysiwyg_wysiwyg__lML8w h4 {
        font-family: fields, arial, system-ui, serif;
        font-weight: 400;
        font-size: 36.491px;
        font-size: 1.265625em;
        line-height: 140%;
}
    .Wysiwyg_wysiwyg__lML8w h5 {
        font-family: fields, arial, system-ui, serif;
        font-weight: 500;
        font-size: 20.25px;
        font-size: 1em;
        line-height: 150%;
}
    .Wysiwyg_wysiwyg__lML8w h6 {
        font-family: fields, arial, system-ui, serif;
        font-weight: 500;
        font-size: 20.25px;
        font-size: 1em;
        line-height: 150%;
}
    .Wysiwyg_wysiwyg__lML8w .text-title-one {
        font-family: fields, arial, system-ui, serif;
        font-weight: 400;
        font-size: 36.491px;
        font-size: 1.8020625em;
        line-height: 128%;
}
    .Wysiwyg_wysiwyg__lML8w .text-title-two {
        font-family: fields, arial, system-ui, serif;
        font-weight: 400;
        font-size: 36.491px;
        font-size: 1.8020625em;
        line-height: 128%;
}
    .Wysiwyg_wysiwyg__lML8w .text-title-three {
        font-family: fields, arial, system-ui, serif;
        font-weight: 400;
        font-size: 36.491px;
        font-size: 1.6018125em;
        line-height: 132%;
}
    .Wysiwyg_wysiwyg__lML8w .text-title-four {
        font-family: fields, arial, system-ui, serif;
        font-weight: 400;
        font-size: 36.491px;
        font-size: 1.4238125em;
        line-height: 136%;
}
    .Wysiwyg_wysiwyg__lML8w .text-title-five {
        font-family: fields, arial, system-ui, serif;
        font-weight: 500;
        font-size: 20.25px;
        font-size: 1em;
        line-height: 150%;
}
    .Wysiwyg_wysiwyg__lML8w .text-title-six {
        font-family: fields, arial, system-ui, serif;
        font-weight: 500;
        font-size: 20.25px;
        font-size: 0.790125em;
        line-height: 150%;
}
    .Wysiwyg_wysiwyg__lML8w .font-weight--light {
        font-weight: 300;
}
    .Wysiwyg_wysiwyg__lML8w .font-weight--regular {
        font-weight: 400;
}
    .Wysiwyg_wysiwyg__lML8w .font-weight--semibold {
        font-weight: 500;
}
    .Wysiwyg_wysiwyg__lML8w .font-weight--bold {
        font-weight: 700;
}
    .Wysiwyg_wysiwyg__lML8w a:not(.button) {
        color: #007ACC;
        text-decoration: underline;
}
    .Wysiwyg_wysiwyg__lML8w a:not(.button):hover,.Wysiwyg_wysiwyg__lML8w a:not(.button):focus {
        text-decoration: none;
}
    .Wysiwyg_wysiwyg__lML8w a:not(.button) {
        word-wrap: break-word;
    }
    .Wysiwyg_wysiwyg__lML8w a:not(.button).Wysiwyg_treatment-block__hkrNl {
            text-decoration: none;
        }
    /* --- Colours --- */
    .Wysiwyg_wysiwyg__lML8w .text-color--alpha,
    .Wysiwyg_wysiwyg__lML8w .text-color--burnt-orange {
        color: #31CC9C;
    }
    .Wysiwyg_wysiwyg__lML8w .text-color--canvas {
        color: #F8F8F8;
    }
    .Wysiwyg_wysiwyg__lML8w .text-color--deep-ocean {
        color: #007ACC;
    }
    .Wysiwyg_wysiwyg__lML8w .text-color--shade,
    .Wysiwyg_wysiwyg__lML8w .text-color--light,
    .Wysiwyg_wysiwyg__lML8w .text-color--mid,
    .Wysiwyg_wysiwyg__lML8w .text-color--slate,
    .Wysiwyg_wysiwyg__lML8w .text-color--washed,
    .Wysiwyg_wysiwyg__lML8w .text-metadata {
        color: #5A5E6E;
    }
    .Wysiwyg_wysiwyg__lML8w .text-color--metro,
    .Wysiwyg_wysiwyg__lML8w .text-color--light-blue {
        color: #72EDC7;
    }
    .Wysiwyg_wysiwyg__lML8w .text-color--midnight {
        color: #31CC9C;
    }
    .Wysiwyg_wysiwyg__lML8w .text-color--success {
        color: #72EDC7;
    }
    .Wysiwyg_wysiwyg__lML8w .text-color--warning {
        color: #FFDF8A;
    }
    .Wysiwyg_wysiwyg__lML8w .text-color--error {
        color: #DB0F64;
    }
    .Wysiwyg_wysiwyg__lML8w .text-color--info {
        color: #007ACC;
    }
    /* --- Buttons --- */
    .Wysiwyg_wysiwyg__lML8w .button {
        padding: 16px 32px;
        font-size: 14.222px;
}
    .Wysiwyg_wysiwyg__lML8w .button--primary {
        background-color: #72EDC7;
        border: 2px solid transparent;
        color: #303C69;
        fill: #303C69;
        text-transform: capitalize;
}
    .Wysiwyg_wysiwyg__lML8w .button--primary:hover {
        background-color: #67d3b1;
        border: 2px solid transparent;
        color: #303C69;
        fill: #303C69;
}
    .Wysiwyg_wysiwyg__lML8w .button--primary:focus {
        background-color: #84efcc;
        border: 2px solid transparent;
        color: #303C69;
        fill: #303C69;
}
    .Wysiwyg_wysiwyg__lML8w .button--primary:focus-visible {
        outline: 2px solid transparent;
}
    .Wysiwyg_wysiwyg__lML8w .button--primary:active {
        background-color: #84efcc;
        border: 2px solid transparent;
        color: #303C69;
        fill: #303C69;
}
    .Wysiwyg_wysiwyg__lML8w .button--primary:disabled {
        background-color: #d9faee;
        border: 2px solid transparent;
        color: #9497b2;
        fill: #9497b2;
        cursor: not-allowed;
}
    .Wysiwyg_wysiwyg__lML8w .button--primary.Wysiwyg_button-disabled___2YtR {
        background-color: #d9faee;
        border: 2px solid transparent;
        color: #9497b2;
        fill: #9497b2;
        cursor: not-allowed;
}
    .Wysiwyg_wysiwyg__lML8w .button--secondary {
        background-color: transparent;
        border: 2px solid #72EDC7;
        color: #303C69;
        fill: #72EDC7;
        text-transform: capitalize;
}
    .Wysiwyg_wysiwyg__lML8w .button--secondary:hover {
        background-color: transparent;
        border: 2px solid #72EDC7;
        color: #303C69;
        fill: #72EDC7;
}
    .Wysiwyg_wysiwyg__lML8w .button--secondary:focus {
        background-color: transparent;
        border: 2px solid #72EDC7;
        color: #303C69;
        fill: #72EDC7;
}
    .Wysiwyg_wysiwyg__lML8w .button--secondary:focus-visible {
        outline: 2px solid #72EDC7;
}
    .Wysiwyg_wysiwyg__lML8w .button--secondary:active {
        background-color: transparent;
        border: 2px solid #72EDC7;
        color: #303C69;
        fill: #72EDC7;
}
    .Wysiwyg_wysiwyg__lML8w .button--secondary:disabled {
        background-color: transparent;
        border: 2px solid #72EDC7;
        color: #9497b2;
        fill: #bff7e3;
        cursor: not-allowed;
}
    .Wysiwyg_wysiwyg__lML8w .button--secondary.Wysiwyg_button-disabled___2YtR {
        background-color: transparent;
        border: 2px solid #72EDC7;
        color: #9497b2;
        fill: #bff7e3;
        cursor: not-allowed;
}
    .Wysiwyg_wysiwyg__lML8w .button--default {
        background-color: #DB0F64;
        border: 2px solid transparent;
        color: #FFFFFF;
        fill: #FFFFFF;
        text-transform: capitalize;
}
    .Wysiwyg_wysiwyg__lML8w .button--default:hover {
        background-color: #c3165a;
        border: 2px solid transparent;
        color: #FFFFFF;
        fill: #FFFFFF;
}
    .Wysiwyg_wysiwyg__lML8w .button--default:focus {
        background-color: #e23e72;
        border: 2px solid transparent;
        color: #FFFFFF;
        fill: #FFFFFF;
}
    .Wysiwyg_wysiwyg__lML8w .button--default:focus-visible {
        outline: 2px solid transparent;
}
    .Wysiwyg_wysiwyg__lML8w .button--default:active {
        background-color: #e23e72;
        border: 2px solid transparent;
        color: #FFFFFF;
        fill: #FFFFFF;
}
    .Wysiwyg_wysiwyg__lML8w .button--default:disabled {
        background-color: #DB0F64;
        border: 2px solid transparent;
        color: #fff;
        fill: #fff;
        cursor: not-allowed;
}
    .Wysiwyg_wysiwyg__lML8w .button--default.Wysiwyg_button-disabled___2YtR {
        background-color: #DB0F64;
        border: 2px solid transparent;
        color: #fff;
        fill: #fff;
        cursor: not-allowed;
}
    /* --- Treatment Cards --- */
    .Wysiwyg_wysiwyg__lML8w .treatment-block {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    @media screen and (min-width: 768px) {
    .Wysiwyg_wysiwyg__lML8w .treatment-block {
            flex-direction: row;
            align-items: flex-start
    }

            .Wysiwyg_wysiwyg__lML8w .treatment-block .treatment-block__image--xs {
                display: none;
            }

            .Wysiwyg_wysiwyg__lML8w .treatment-block .treatment-block__image--md {
                display: block;
            }
        }
    .Wysiwyg_wysiwyg__lML8w .treatment-block__image {
        max-width: 4rem;
    }
    .Wysiwyg_wysiwyg__lML8w .treatment-block__image--xs {
        display: block;
        margin-right: 16px;
    }
    .Wysiwyg_wysiwyg__lML8w .treatment-block__image--md {
        display: none;
    }
    .Wysiwyg_wysiwyg__lML8w .treatment-block__subtitle {
        margin-bottom: 24px;
    }

.HelpContent_helpContent__Dr7X4 {

    gap: 32px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    padding: 16px;
    border-radius: 12px;
    border: 1px solid #007ACC;
    z-index: 1;
    /* To stop weight being overridden by the wysiwyg. */
    font-weight: 400 !important
}

.QuestionCard_questionCard__wUHXq {
    /* We set text right on the panel so that the button aligns right without extra markup and classes. */
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 24px 16px;
    text-align: left;
    transition: opacity 200ms linear;
}

    @media screen and (min-width: 768px) {.QuestionCard_questionCard__wUHXq {
        flex-direction: row
}
    }

    .QuestionCard_questionCard__wUHXq.QuestionCard_currentStep__I1pyu ~ .QuestionCard_questionCard__wUHXq:not(.QuestionCard_dirty__1ZPNn) {
            opacity: 0.4;
            pointer-events: none;
        }

    .QuestionCard_questionCard__wUHXq textarea {
        min-height: 64px;
    }

.QuestionCard_currentStep__I1pyu {
    opacity: 1;
}

.QuestionCard_icon__hZtZR {
    flex-shrink: 0;
    margin-left: auto;
    opacity: 0;
    transition: 0.3s opacity ease-in-out;
}

.QuestionCard_dirty__1ZPNn {
    opacity: 1;
}

.QuestionCard_dirty__1ZPNn .QuestionCard_icon__hZtZR {
        opacity: 1;
    }

.QuestionCard_question__hz_bY {
    font-family: fields, arial, system-ui, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
}

.QuestionCard_question__hz_bY > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

.QuestionCard_question__hz_bY {
    line-height: 140%;

    /* To stop weight being overridden by the wysiwyg. */
    font-weight: 500 !important;
}

.QuestionCard_question__hz_bY a {
    color: #007ACC;
    text-decoration: underline;
}

.QuestionCard_question__hz_bY a:hover,.QuestionCard_question__hz_bY a:focus {
    text-decoration: none;
}

.QuestionCard_questionDescription__5t52E {
    font-family: sofia-pro, arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.QuestionCard_questionDescription__5t52E > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

.QuestionCard_questionDescription__5t52E {

    /* To stop weight being overridden by the wysiwyg. */
    font-weight: 400 !important;
}

.QuestionCard_questionDescription__5t52E a {
    color: #007ACC;
    text-decoration: underline;
}

.QuestionCard_questionDescription__5t52E a:hover,.QuestionCard_questionDescription__5t52E a:focus {
    text-decoration: none;
}

.QuestionCard_question__hz_bY ul,
.QuestionCard_questionDescription__5t52E ul {
    font-family: sofia-pro, arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    padding: 0;
    margin: 0;
    list-style-type: disc;
    padding-left: 16px;
}

.QuestionCard_question__hz_bY ul li, .QuestionCard_questionDescription__5t52E ul li {
    font-size: 14.222px;
    line-height: 150%;
        padding-left: 8px;
}

.QuestionCard_question__hz_bY ul img, .QuestionCard_questionDescription__5t52E ul img {
        margin-top: 8px;
    }

.QuestionCard_providedOn__NzajK {
    padding: 12px;
    background: #F8F8F8;
    border-radius: 12px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.AccountConsultationSelected_answerTitle__X4NZT > p:first-child {
        font-family: fields, arial, system-ui, serif;
        font-weight: 600;
    }

.AccountOrderNotes_card__4OeEf {

    display: flex;

    width: 100%;

    flex-direction: column;

    padding: 0;
}

.AccountOrderNotes_header__hHWpk {

    display: flex;

    width: 100%;

    flex-direction: row;

    align-items: center;

    justify-content: space-between;

    padding-top: 24px;

    padding-bottom: 24px;

    padding-left: 16px;

    padding-right: 16px;

    border-bottom: 1px solid #CAD8E2;
}

.AccountOrderNotes_content__FFU_e > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(32px * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(32px * var(--tw-space-y-reverse));
}

.AccountOrderNotes_content__FFU_e {

    padding: 24px 16px;
}
.ProfileCard_listItem__f7bw4 {
    position: relative;
    padding-top: 8.375rem;
}

.ProfileCard_image__VCbZK {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px 8px 128px 128px;
}

.ProfileCard_card___7ltA {
    height: 100%;
    padding: 8.625rem 16px 16px;
}

.ProfileCard_link__wh_Ot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
}

.CharityContent_charityCategory__X4efg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    max-width: 1152px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;
}

    .CharityContent_charityCategory__X4efg img {
        -o-object-fit: cover;
           object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @media (min-width: 655px) {

    .CharityContent_charityCategory__X4efg:nth-child(even) {
        flex-direction: row-reverse;
    }
        }

    .CharityContent_charityCategory__X4efg:nth-child(even) .CharityContent_charityImageContainer___qeGu {
            border-radius: 12px;
            border-top-right-radius: 96px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            margin-left: -1rem;
        }

    @media (min-width: 655px) {

    .CharityContent_charityCategory__X4efg:nth-child(even) .CharityContent_charityImageContainer___qeGu {
                border-radius: 12px;
                border-top-right-radius: 96px;
                margin-left: 0;
                margin-right: 0
        }.CharityContent_charityCategory__X4efg {
        padding-left: 24px;
        padding-right: 24px;
        flex-direction: row;
    }
            }

.CharityContent_charityAssets__P_E3E {
    position: relative;
}

.CharityContent_charityBody__ATShy {
    flex-grow: 1;
}

.CharityContent_charityBody__ATShy > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px * var(--tw-space-y-reverse));
}

@media (min-width: 655px) {

.CharityContent_charityBody__ATShy {
        flex-basis: 70%;
        padding-left: 0;
        padding-right: 0
}
    }

.CharityContent_charityImageContainer___qeGu {
    overflow: hidden;
    border-radius: 12px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-left: 16px;
    border-top-left-radius: 96px;
    aspect-ratio: 13/8;
    width: 100%;
}

@media (min-width: 655px) {

.CharityContent_charityImageContainer___qeGu {
        border-radius: 12px;
        border-top-left-radius: 96px;
        margin-left: 0;
        margin-right: 0
}
    }

.NoRecommendedTreatments_section__B1RU1 {
    align-self: center;
    max-width: 720px;
    padding: 0 16px;
    margin: 64px auto;
}

    @media (min-width: 768px) {.NoRecommendedTreatments_section__B1RU1 {
        padding: 0 24px
}
    }

.NoRecommendedTreatments_link__KB29E {
    color: #007ACC;
    text-decoration: underline;
}

.NoRecommendedTreatments_link__KB29E:hover,.NoRecommendedTreatments_link__KB29E:focus {
    text-decoration: none;
}

@media (max-width: 23.5rem) {

.NoRecommendedTreatments_link__KB29E {
        word-break: break-all
}
    }

.SearchInput_wrapper__Al8pr {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    align-items: center;
    padding: 8px 8px 8px 20px;
    border-radius: 1000px;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
    transition: border-color 300ms cubic-bezier(0.23, 1, 0.32, 1);
    box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
}

    .SearchInput_wrapper__Al8pr:focus-within {
        border-color: #007ACC;
    }

    @supports (container-type: inline-size) {.SearchInput_wrapper__Al8pr {
        flex-wrap: wrap
}
    }

    @container (min-width: 320px) {.SearchInput_wrapper__Al8pr {
        flex-wrap: nowrap
}
    }

.SearchInput_wrapperMobileStack__GMPGL {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 24px;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: border-color 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.SearchInput_wrapperMobileStack__GMPGL:focus-within {
        border-color: #007ACC;
    }

@container (min-width: 375px) {

.SearchInput_wrapperMobileStack__GMPGL {
        flex-direction: row;
        align-items: center;
        border-radius: 1000px;
        justify-content: space-between;
        padding: 8px 8px 8px 20px;
        gap: 12px
}

        .SearchInput_wrapperMobileStack__GMPGL div {
        padding-left: 0;
        padding-right: 0;
    }
    }

.SearchInput_wrapperBordered__ECBG_ {
    border-color: #CAD8E2;
    background-color: #FFFFFF;
}

.SearchInput_wrapperBordered__ECBG_:focus-within {
        background-color: #FFFFFF;
    }

.SearchInput_wrapperShadow__LV7gp {
    border-color: #FFFFFF;
    background-color: #FFFFFF;
    box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
}

.SearchInput_wrapperShadow__LV7gp:focus-within {
        background-color: #FFFFFF;
        box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
    }

@container (max-width: 319px) {

.SearchInput_hasButton__nZdoV {
        padding: 16px 16px 8px;
        border-radius: 24px
}
    }

.SearchInput_searchIconRight__lx72g {
    order: 2;
    padding: 8px;
    box-sizing: content-box;
}

.SearchInput_input__fBAVO {
    font-size: 14.222px;
    line-height: 150%;
    flex-grow: 1;
    min-width: 0;
    background-color: transparent; /* Safari adds a grey background colour by default. */
    color: #5A5E6E;
}

.SearchInput_input__fBAVO::-moz-placeholder {
        color: inherit;
        text-overflow: ellipsis;
    }

.SearchInput_input__fBAVO::placeholder {
        color: inherit;
        text-overflow: ellipsis;
    }

.SearchInput_input__fBAVO:-moz-placeholder-shown {
        text-overflow: ellipsis;
    }

.SearchInput_input__fBAVO:placeholder-shown {
        text-overflow: ellipsis;
    }

.SearchInput_input__fBAVO:focus,
    .SearchInput_input__fBAVO:focus-visible {
        outline: none;
    }

.SearchInput_input__fBAVO::-webkit-search-cancel-button {
        -webkit-appearance: none;
        cursor: pointer;
    }

@supports (container-type: inline-size) {

.SearchInput_button__4NvmB {
        margin-left: -8px;
        margin-right: -8px;

        width: calc(100% + 16px);
    }
    }

.SearchInput_button__4NvmB:focus,
    .SearchInput_button__4NvmB:focus-visible {
        outline: none;
    }

@container (min-width: 320px) {

.SearchInput_button__4NvmB {
        flex-grow: 0;
        flex-shrink: 0;
        width: auto;
        margin: 0
}
    }

@supports (container-type: inline-size) {

.SearchInput_mobileStackButton__ARhVq {
        margin-left: -16px;
        margin-right: -16px;
        width: 100%;
        margin: 0 auto;
    }

    }

.SearchInput_mobileStackButton__ARhVq:focus,
    .SearchInput_mobileStackButton__ARhVq:focus-visible {
        outline: none;
    }

@container (min-width: 375px) {

.SearchInput_mobileStackButton__ARhVq {
        width: calc(100% + 16px);
        flex-grow: 0;
        flex-shrink: 0;
        width: auto;
        margin: 0
}
    }


.TreatmentResults_card__1VnsI {
    --card-padding: 0;
}

.TreatmentResults_image__CzUik {
    align-self: flex-start;
    width: 64px;
}

.ConditionResults_link__xuFjT {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    margin: 0 !important;
}

.ConditionResults_description__ZEPcv a {
    color: #007ACC;
    text-decoration: underline;
}

.ConditionResults_description__ZEPcv a:hover,.ConditionResults_description__ZEPcv a:focus {
    text-decoration: none;
}

.ConditionResults_description__ZEPcv a {
    position: relative;
    z-index: 20;
}

.SearchEmpty_searchEmpty__8QSqW > :not([hidden]) ~ :not([hidden]){

    --tw-space-y-reverse: 0;

    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(8px * var(--tw-space-y-reverse));
}.SearchEmpty_searchEmpty__8QSqW{

    padding: 16px;
    border: 1px dashed #CAD8E2;
    border-radius: 12px;
}

.SearchEmpty_linkItem__DkFIn{

    display: flex;

    align-items: flex-start;

    gap: 12px;
}

.SiteBanner_banner__O76Mp {

    position: relative;

    z-index: 1;
}.SiteBanner_banner__O76Mp:before {

    content: '';

    position: absolute;

    left: 0;

    right: 0;

    height: 40px;

    background: var(--before-bg-color, var(--after-bg-color, #CCF2FF));

    z-index: 1;

    top: -40px;
}.SiteBanner_banner__O76Mp {

    position: relative;
    width: 100%;
    max-height: 0;
    background-color: #CCF2FF;
    opacity: 0;
    transition:
        opacity 600ms ease-in-out,
        max-height 600ms ease-in-out;
    z-index: 450;
}

    @media (min-width: 1024px) {.SiteBanner_banner__O76Mp {
        z-index: 2
}

        .SiteBanner_banner__O76Mp:before {
            content: none;
        }
    }

    .SiteBanner_banner__O76Mp svg {
        fill: #FFFFFF;
    }

.SiteBanner_bannerAnimateIn__MwKYA {
    max-height: 5rem;
    opacity: 1;
}

.SiteBanner_content__qqTkV {

    max-width: 1152px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 8px 16px;
    margin: 0 auto;
    overflow: hidden;
}

.SiteBanner_content__qqTkV > div {

    font-size: 12.642px;

    line-height: 150%;
}

.SiteBanner_content__qqTkV > div a {

    color: #007ACC;

    text-decoration: underline;
}

.SiteBanner_content__qqTkV > div a:hover,.SiteBanner_content__qqTkV > div a:focus {

    text-decoration: none;
}

.SiteBanner_wysiwyg__HgSoT {

    font-size: 12.642px;

    line-height: 150%;

    flex-grow: 1;
    width: 100%;
    color: #303C69;
    text-align: center;
}

.SiteBanner_wysiwyg__HgSoT a {

    color: #007ACC;

    text-decoration: underline;
}

.SiteBanner_wysiwyg__HgSoT a:hover,.SiteBanner_wysiwyg__HgSoT a:focus {

    text-decoration: none;
}

.SiteBanner_wysiwyg__HgSoT a {

    font-size: 14.222px;

    line-height: 150%;

        font-weight: 700;
}

.TreatmentCardPrice_container__IvxE2 {
    display: flex;
    gap: 8px;
    align-items: baseline;
}

.TreatmentCardPrice_prefix__45Q_y {
    display: block;
    text-transform: capitalize;
}

.TreatmentCardPrice_price__jtI_j {
    display: inline-block;
    font-size: 18px;
    line-height: 110%;
    color: #007ACC;
    vertical-align: bottom;
}

@container (min-width: 310px) {

.TreatmentCardPrice_price__jtI_j {
        font-size: 18px
}
    }

.TreatmentCard_card__mhs6w > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(12px * var(--tw-space-y-reverse));
}

.TreatmentCard_card__mhs6w {

    padding: var(--card-padding);

    container-type: inline-size;
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    box-shadow: none;
    overflow: hidden;
    transition: box-shadow 350ms ease-out;
}

    .TreatmentCard_card__mhs6w:hover {
        box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
        --card-border: 1px solid #CAD8E2;
    }

/* --- Children --- */

.TreatmentCard_header__hlSzi {
    padding: 0 12px;
    padding-top: 12px;
}

@container (min-width: 215px) {

.TreatmentCard_header__hlSzi {
        padding: 0 16px;
        padding-top: 24px
}

        .TreatmentCard_header__hlSzi h3 {
            font-size: 20.25px;
            line-height: 136%;
        }
    }

.TreatmentCard_main__duvr7 {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 12px;
    padding-bottom: 12px;
}

@container (min-width: 215px) {

.TreatmentCard_main__duvr7 {
        padding: 0 16px;
        padding-bottom: 24px
}
    }

.TreatmentCard_tagWrapper__FgXYk {
    height:26px;
}

.TreatmentCard_button__m_Vs4 {
    display: block;
    width: 100%;
    margin-top: 8px;
}

@container (min-width: 215px) {

.TreatmentCard_button__m_Vs4 {

        padding: 16px 32px;

        font-size: 14.222px;
    }
    }

.TreatmentCard_link__ZZj_u {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    /* Need to override tailwind space-y class. */

    opacity: 1;

    outline: 2px solid transparent;

    outline-offset: 2px;
}

.TreatmentCard_link__ZZj_u:focus {

    border-radius: 12px;

    outline-width: 2px;

    outline-offset: 0px;

    outline-color: #72EDC7;
}

.TreatmentCard_image__udjqd {
    display: block;
    margin: 0 auto;
    width: 7.75rem;
    height: 7.75rem;
    -o-object-fit: contain;
       object-fit: contain;
}

@container (min-width: 215px) {

.TreatmentCard_image__udjqd {
        width: 10rem;
        height: 10rem
}
    }

.TreatmentCard_starRating__JLnfG {
    flex-shrink: 0;
}

@container (max-width: 10.5rem) {

.TreatmentCard_starRating__JLnfG img {
            width: 8px;
            height: 8px
    }
        }

/* Variants */

.TreatmentCard_specialOffer__iJ0FQ {
    --card-border: 1px solid #DB0F64;
}

.TreatmentCard_specialOffer__iJ0FQ:hover {
        box-shadow: 0px 0px 0px 6px rgba(219, 15, 100, 0.15);
        --card-border: 1px solid #DB0F64;
    }

.TreatmentCard_bestSeller__W__kL {
    --card-border: 1px solid #72EDC7;
}

.TreatmentCard_bestSeller__W__kL:hover {
        box-shadow: 0px 0px 0px 6px rgba(114, 237, 199, 0.20);
        --card-border: 1px solid #72EDC7;
    }

.TreatmentCard_bundlePack__z__bc {
    --card-border: 1px solid #FFDF8A;
}

.TreatmentCard_bundlePack__z__bc:hover {
        box-shadow: 0px 0px 0px 6px rgba(255, 223, 138, 0.15);
        --card-border: 1px solid #FFDF8A;
    }

.TreatmentCard_newProduct__rlUVI {
    --card-border: 1px solid #303C69;
}

.TreatmentCard_newProduct__rlUVI:hover {
        box-shadow: 0px 0px 0px 6px rgba(48, 60, 105, 0.15);
        --card-border: 1px solid #303C69;
    }

.TreatmentCard_multiBuyOffer__7YQAL {
    --card-border: 1px solid #007ACC;
}

.TreatmentCard_multiBuyOffer__7YQAL:hover {
        box-shadow: 0px 0px 0px 6px rgba(0, 122, 204, 0.15);
        --card-border: 1px solid #007ACC;
    }

.TreatmentCard_newPackaging__ipqOV {
    --card-border: 1px solid #56E7FF;
}

.TreatmentCard_newPackaging__ipqOV:hover {
        box-shadow: 0px 0px 0px 6px rgba(86, 231, 255, 0.15);
        --card-border: 1px solid #56E7FF;
    }

.TreatmentCard_sale__C9tBH {
    --card-border: 1px solid #FF549D;
}

.TreatmentCard_sale__C9tBH:hover {
        box-shadow: 0px 0px 0px 6px rgba(255, 84, 157, 0.15);
        --card-border: 1px solid #FF549D;
    }
.FilterTagCloud_cloud__lyzZQ {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
}

.FilterTagCloud_tag__dH11G {
    display: flex !important;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
}

.FilterTagCloud_button__0nig_ {
    margin-top: -2px;
    margin-bottom: -2px;
    margin-left: -4px;
    margin-right: -4px;

    padding: 4px;
}

.TagCloud_cloud__LaHv5 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
}

.TagCloud_tag__xb362 {
    display: inline-block;
    padding: 4px 8px;
    --tag-font-size: 9.989px;
    line-height: 100%;
}

@media (min-width: 1024px) {

.TagCloud_tag__xb362 {
        padding: 8px 16px;
        --tag-font-size: 11.237px
}
    }

@media (min-width: 768px) {.OrderAgainTreatments_grid__exPZ4 > li:nth-child(4) {
            display: none
    }
        }

.SearchOverlay_overlay__eOSPj {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px 0;
    margin-left: 0;
    background: #FFFFFF;
    overflow: auto;
    z-index: 800;
    opacity: 0;
    transition-property: opacity;
}

    @media screen and (min-width: 768px) {.SearchOverlay_overlay__eOSPj {
        padding: 32px 0
}
    }

.SearchOverlay_fadeIn__J6mHR {
    opacity: 1;
    transition-duration: 600ms;
    transition-timing-function: cubic-bezier(0.15, 0.66, 0.46, 0.98);
}

.SearchOverlay_fadeOut__SRC_Y {
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.6, 0.1, 0.87, 0.4);
    pointer-events: none;
}

.SortAndFilterDialog_dialog__ldG_t {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100vw;
    height: 100dvh;

}

    @media (min-width: 768px) {.SortAndFilterDialog_dialog__ldG_t {
        align-items: center

}
    }

.SortAndFilterDialog_panel__WMZqg {
    display: flex;
    flex-direction: column;
    max-width: 38.75rem;
    width: 100%;
    border-radius: 4px 4px 0 0;
    background-color: #FFFFFF;
    
    overflow: hidden;
    transition: transform 300ms ease-in-out;
}

@media (min-width: 768px) {

.SortAndFilterDialog_panel__WMZqg {
        max-height: calc(100% - 10.125rem);
        border-radius: 24px;
}
    }

.SortAndFilterDialog_panel__WMZqg {

    z-index: 0;

}

.SortAndFilterDialog_panelWindowHeight__AmOdw {
    height: calc(100% - 3.25rem);
}

.SortAndFilterDialog_header__5tcsB {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    border-bottom: 1px solid #CAD8E2;
    flex-grow: 0;
    flex-shrink: 0;
}

.SortAndFilterDialog_closeButton__I4Inu {
    margin: -8px;

    padding: 8px;

}

.SortAndFilterNoResults_clearAll__PxO2a span {
        text-decoration: underline;
    }

    .SortAndFilterNoResults_clearAll__PxO2a:hover span {
            text-decoration: none;
        }

.ConditionPageTreatments_treatments__tR61i {

    grid-template-columns: repeat(2, minmax(0, 1fr));

    display: grid;
    gap: 12px
}

    @media (min-width: 768px) {.ConditionPageTreatments_treatments__tR61i {

        grid-template-columns: repeat(3, minmax(0, 1fr));

        gap: 16px
    }
    }

.GenericPage_container__S9skI {
        max-width: 960px;
        padding-left: 16px;
        padding-right: 16px;
        margin-left: auto;
        margin-right: auto;
}

@media (min-width: 768px) {

        .GenericPage_container__S9skI {
                padding-left: 24px;
                padding-right: 24px;
        }
}

.GenericPage_spacing__Qydoz {
        margin-top: 48px;
}

@media (min-width: 1024px) {

        .GenericPage_spacing__Qydoz {
                margin-top: 64px;
        }
}

.GenericPage_spacing__Qydoz:last-child {
        margin-bottom: 48px;
    }

@media (min-width: 1024px) {

.GenericPage_spacing__Qydoz:last-child {
            margin-bottom: 64px
    }
        }
.BrandCard_card__BRSDo {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.BrandCard_link__blcdg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    font-size: 0;
}

.BrandCard_link__blcdg:focus {
    outline-width: 2px;
}

.BrandCard_link__blcdg:focus:focus {
    outline-color: #007ACC;
}

.ConditionCard_card__ZcyH_ {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    transition: box-shadow 350ms ease-out;
}

    .ConditionCard_card__ZcyH_:hover {
        box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
    }

.ConditionCard_link__Bwjj_ {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    font-size: 0;
}

.ConditionCard_link__Bwjj_:focus {
    outline-width: 2px;
}

.ConditionCard_link__Bwjj_:focus:focus {
    outline-color: #007ACC;
}

.FeaturedConditionCard_card__dRK7t {
    display: flex;
    flex-direction:column;
    gap:16px;
    justify-content:space-between;
    transition: box-shadow 350ms ease-out;
}

    .FeaturedConditionCard_card__dRK7t:hover {
        box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
    }

.FeaturedConditionCard_intro__2R6O5 * {

    display:none;   
}

.FeaturedConditionCard_intro__2R6O5 *:first-child {
        display:-webkit-box;
        overflow:hidden;
        line-clamp: 4;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
.OrderingIsEasy_variantDark__qUB8v {
    background: #303C69;
    --border-color: #ccf2ff80;
    --shield-color: #FFFFFF;
}

    .OrderingIsEasy_variantDark__qUB8v h2,
    .OrderingIsEasy_variantDark__qUB8v h5 {
        color: #CCF2FF;
    }

    .OrderingIsEasy_variantDark__qUB8v p {
        color: #FFFFFF;
    }

.OrderingIsEasy_variantLight__d3csY {
    border: 1px solid #CCF2FF;
    padding: 48px;
    background: #FFFFFF;
    --border-color: #CAD8E2;
    --shield-color: #F8F8F8;
}

.OrderingIsEasy_wrapper__C3eF_ {
    padding: 8px;
    border-radius: 24px;
}

@media (min-width: 465px) {

.OrderingIsEasy_wrapper__C3eF_ {
        padding: 16px
}
    }

@media (min-width: 960px) {

.OrderingIsEasy_wrapper__C3eF_ {
        padding: 48px
}
    }

.OrderingIsEasy_container__8piTj {
    padding: 32px 0;
    border: 1px solid var(--border-color, #ccf2ff80);
    border-radius: inherit;
}

@media (min-width: 465px) {

.OrderingIsEasy_container__8piTj {
        padding: 48px 0
}
    }

.OrderingIsEasy_container__8piTj h2 {
        padding: 0 16px;
        text-align: center;
    }

@media (min-width: 465px) {

.OrderingIsEasy_container__8piTj h2 {
            padding: 0 24px
    }
        }

.OrderingIsEasy_list__uNJVB {
    max-width: 480px;
    margin: 32px auto 0;
    padding: 0 24px;
    text-align: center;
}

@media (min-width: 465px) and (max-width: 760px) {

.OrderingIsEasy_list__uNJVB {
        text-align: left
}
    }

@media (min-width: 760px) {

.OrderingIsEasy_list__uNJVB {
        display: flex;
        justify-content: space-between;
        max-width: 960px
}
    }

.OrderingIsEasy_listItem__28AWe {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media (min-width: 465px) and (max-width: 759px) {

.OrderingIsEasy_listItem__28AWe {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px
}
    }

.OrderingIsEasy_listItemArrow__WoqOo {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    height: 2rem;
}

@media (min-width: 760px) {

.OrderingIsEasy_listItemArrow__WoqOo {
        width: 48px;
        height: auto
}
    }

.OrderingIsEasy_mobileArrow__7ZSKe {
    display: none;
}

@media (min-width: 465px) and (max-width: 759px) {

.OrderingIsEasy_mobileArrow__7ZSKe {
        position: absolute;
        top: -0.35rem;
        left: 32px;
        display: block
}

        .OrderingIsEasy_mobileArrow__7ZSKe.OrderingIsEasy_mobileArrowLeft__r9d_n {
            left: 0;
        }
    }

.OrderingIsEasy_arrowPosition___QaXH {
    position: absolute;
    top: 0;
    left: 50%;
    max-width: none;
    transform: translateX(-51%);
}

.OrderingIsEasy_arrowPosition___QaXH.OrderingIsEasy_arrowPositionLowered__Xgj4A {
        top: 16px;
    }

.OrderingIsEasy_tabletArrow__EFP7W {
    display: none;
}

@media (min-width: 760px) and (max-width: 959px) {

.OrderingIsEasy_tabletArrow__EFP7W {
        display: block
}
    }

.OrderingIsEasy_desktopArrow__Qhrh2 {
    display: none;
}

@media (min-width: 960px) {

.OrderingIsEasy_desktopArrow__Qhrh2 {
        display: block;
        max-width: 12rem
}
    }

@media (min-width: 1000px) {

.OrderingIsEasy_desktopArrow__Qhrh2 {
        max-width: none
}
    }

.OrderingIsEasy_shield__BX_B8 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 12px;
    margin: 0 auto;
    box-sizing: content-box;
    border-radius: 2px 2px 40px 40px;
    background: var(--shield-color, #FFFFFF);
    overflow: visible;
}

@media (min-width: 465px) and (max-width: 759px) {

.OrderingIsEasy_shield__BX_B8 {
        margin: 0
}
    }

.ServiceStamp_wrapper__AGu4b {

    container-type: inline-size;

    display: flex;
    flex-wrap: wrap;
    align-items: center
}

@container (min-width: 244px) {

.ServiceStamp_description__EYGAA {
        order: 1
}
    }

.ServiceStamp_rating__z052Q {
    padding-left: 8px;
}

@container (min-width: 244px) {

.ServiceStamp_rating__z052Q {
        order: 4;
        margin-top: 4px
}
    }

@container (min-width: 453px) {

.ServiceStamp_rating__z052Q {
        margin-top: 0
}
    }

.ServiceStamp_reviews__Bo327 {
    margin-top: 4px;
    text-align: center;
}

@container (min-width: 244px) {

.ServiceStamp_reviews__Bo327 {
        order: 2;
        margin-top: 0;
        padding-left: 4px
}
    }

.ServiceStamp_spacer__gOnzV {
    display: none;
}

@container (min-width: 244px) {

.ServiceStamp_spacer__gOnzV {
        order: 3;
        display: block;
        width: 100%
}
    }

@container (min-width: 453px) {

.ServiceStamp_spacer__gOnzV {
        display: none
}
    }

.ServiceStamp_reviewsLogo__zClZf {
    margin-top: 8px;
    max-width: none;
}

@container (min-width: 244px) {

.ServiceStamp_reviewsLogo__zClZf {
        order: 5;
        padding-left: 12px;
        margin-top: 4px
}
    }

@container (min-width: 453px) {

.ServiceStamp_reviewsLogo__zClZf {
        padding-left: 4px;
        margin-top: 0
}
    }

.FeaturedTreatmentCard_card__uJxT_ > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(16px * var(--tw-space-y-reverse));
}.FeaturedTreatmentCard_card__uJxT_ {

    display: flex;
    flex-direction:column;
    justify-content: space-between;
    position: relative;
    height: 100%;
    padding: 24px 16px;
    box-shadow: none;
    transition: box-shadow 350ms ease-out;
    overflow: hidden;
}

    .FeaturedTreatmentCard_card__uJxT_:hover {
        box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
        --card-border: 1px solid #CAD8E2;
    }

/* Variants */

.FeaturedTreatmentCard_specialOffer__w300x {
    --card-border: 1px solid #DB0F64;
}

.FeaturedTreatmentCard_specialOffer__w300x:hover {
        box-shadow: 0px 0px 0px 6px rgba(219, 15, 100, 0.15);
        --card-border: 1px solid #DB0F64;
    }

.FeaturedTreatmentCard_bestSeller__GTlSp {
    --card-border: 1px solid #72EDC7;
}

.FeaturedTreatmentCard_bestSeller__GTlSp:hover {
        box-shadow: 0px 0px 0px 6px rgba(114, 237, 199, 0.20);
        --card-border: 1px solid #72EDC7;
    }

.FeaturedTreatmentCard_bundlePack__uCuW7 {
    --card-border: 1px solid #FFDF8A;
}

.FeaturedTreatmentCard_bundlePack__uCuW7:hover {
        box-shadow: 0px 0px 0px 6px rgba(255, 223, 138, 0.15);
        --card-border: 1px solid #FFDF8A;
    }

.FeaturedTreatmentCard_newProduct__T2XVN {
    --card-border: 1px solid #303C69;
}

.FeaturedTreatmentCard_newProduct__T2XVN:hover {
        box-shadow: 0px 0px 0px 6px rgba(48, 60, 105, 0.15);
        --card-border: 1px solid #303C69;
    }

.FeaturedTreatmentCard_multiBuyOffer__Cjzu6 {
    --card-border: 1px solid #007ACC;
}

.FeaturedTreatmentCard_multiBuyOffer__Cjzu6:hover {
        box-shadow: 0px 0px 0px 6px rgba(0, 122, 204, 0.15);
        --card-border: 1px solid #007ACC;
    }

.FeaturedTreatmentCard_newPackaging__xIFpa {
    --card-border: 1px solid #56E7FF;
}

.FeaturedTreatmentCard_newPackaging__xIFpa:hover {
        box-shadow: 0px 0px 0px 6px rgba(86, 231, 255, 0.15);
        --card-border: 1px solid #56E7FF;
    }

.FeaturedTreatmentCard_sale__veb68 {
    --card-border: 1px solid #FF549D;
}

.FeaturedTreatmentCard_sale__veb68:hover {
        box-shadow: 0px 0px 0px 6px rgba(255, 84, 157, 0.15);
        --card-border: 1px solid #FF549D;
    }

/* --- Children --- */

.FeaturedTreatmentCard_tag__StSRb {
    position:absolute;
    top: 0;
    left: 0;
    --tag-padding: 8px 16px;
    --tag-font-size: 11.237px;
}

.FeaturedTreatmentCard_button__o8wga {
    display: block;
    width: 100%;
}

@container (min-width: 215px) {

.FeaturedTreatmentCard_button__o8wga {

        padding: 16px 32px;

        font-size: 14.222px;
    }
    }

.FeaturedTreatmentCard_link__jv7Kz {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    /* Need to override tailwind space-y class. */

    opacity: 1;

    outline: 2px solid transparent;

    outline-offset: 2px;
}

.FeaturedTreatmentCard_link__jv7Kz:focus {

    border-radius: 12px;

    outline-width: 2px;

    outline-offset: 0px;

    outline-color: #72EDC7;
}

.FeaturedTreatmentCard_image__NoBY6 {
    display: block;
    margin: 0 auto;
    width: 7.5rem;
    height: 7.5rem;
    -o-object-fit: contain;
       object-fit: contain;
    flex-grow: 0;
}

@container (min-width: 215px) {

.FeaturedTreatmentCard_image__NoBY6 {
        width: 8.75rem;
        height: 8.75rem
}
    }

.FeaturedTreatmentCard_featuresList__PMIz0 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

.FeaturedTreatmentCard_featuresList__PMIz0 {

    flex-grow: 1;
}
.TrustBlock_list__EH0Jz {

    grid-template-columns: repeat(1, minmax(0, 1fr));

    display: grid;
    gap: 24px
}

    @media (min-width: 768px) {.TrustBlock_list__EH0Jz {

        grid-template-columns: repeat(2, minmax(0, 1fr));

        -moz-column-gap: 24px;

             column-gap: 24px;
        row-gap: 24px
    }
    }

    @media (min-width: 1024px) {.TrustBlock_list__EH0Jz {

        grid-template-columns: repeat(4, minmax(0, 1fr));

        gap: 24px
    }
    }

.AffiliateDiscountBanner_banner__EmLM8 {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    padding: 16px;
    border-radius: 12px;
    border: 1px dashed #007ACC;
    background-color: #FFFFFF;
}

