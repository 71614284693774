.flashMessage {
    position: fixed;
    bottom: 4.25rem; /* Places it above the draw nav component. */
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 theme('spacing.100');
    z-index: theme('zIndex.toast');

    @media screen and (min-width: theme('screens.md')) {
        bottom: theme('spacing.200');
    }
}
