.helpContent {
    @apply gap-200;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    padding: theme('spacing.100');
    border-radius: theme('borderRadius.100');
    border: 1px solid theme('colors.status.info.default');
    z-index: 1;
    /* To stop weight being overridden by the wysiwyg. */
    font-weight: 400 !important;
}
