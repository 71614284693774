.logos {
    display: flex;
    gap: theme('spacing.050');
    margin-left: auto;

    img {
        height: 1.5rem;
        border: 1px solid theme('colors.decorators.subtle');
        border-radius: theme('borderRadius.050');
        flex-shrink: 1;
    }
}

.message {
    @apply type-090 space-y-100;

    margin-top: theme('spacing.100');

    h3 {
        @apply type-heading type-090;
    }

    ul {
        list-style: disc;
        padding-left: theme('spacing.150');
    }

    :global(.flow--small) {
        @apply space-y-100;
    }
}
