.wrapper {
    @apply @container;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.description {
    @container (min-width: 244px) {
        order: 1;
    }
}

.rating {
    padding-left: theme('spacing.050');

    @container (min-width: 244px) {
        order: 4;
        margin-top: theme('spacing.025');
    }

    @container (min-width: 453px) {
        margin-top: 0;
    }
}

.reviews {
    margin-top: theme('spacing.025');
    text-align: center;

    @container (min-width: 244px) {
        order: 2;
        margin-top: 0;
        padding-left: theme('spacing.025');
    }
}

.spacer {
    display: none;

    @container (min-width: 244px) {
        order: 3;
        display: block;
        width: 100%;
    }

    @container (min-width: 453px) {
        display: none;
    }
}

.reviewsLogo {
    margin-top: theme('spacing.050');
    max-width: none;

    @container (min-width: 244px) {
        order: 5;
        padding-left: theme('spacing.075');
        margin-top: theme('spacing.025');
    }

    @container (min-width: 453px) {
        padding-left: theme('spacing.025');
        margin-top: 0;
    }
}
