.searchEmpty{

    @apply space-y-050;

    padding: theme('padding.100');
    border: 1px dashed theme('colors.decorators.subtle');
    border-radius: theme('borderRadius.100');
}

.linkItem {
    @apply flex gap-075 items-start;
}
