.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: theme('spacing.150') 0;
    margin-left: 0;
    background: theme('colors.layers.foreground');
    overflow: auto;
    z-index: theme('zIndex.800');
    opacity: 0;
    transition-property: opacity;

    @media screen and (min-width: theme('screens.md')) {
        padding: theme('spacing.200') 0;
    }
}

.fadeIn {
    opacity: 1;
    transition-duration: theme('transitionDuration.default');
    transition-timing-function: theme('transitionTimingFunction.easeIn');
}

.fadeOut {
    transition-duration: theme('transitionDuration.fast');
    transition-timing-function: theme('transitionTimingFunction.easeOut');
    pointer-events: none;
}
