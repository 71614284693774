/*
 * Background set to important as button-reset was un-setting the background-color classes and in certain
 * states was making the button transparent in certain conditions.
 */

.button {
    @apply card card-bordered;

    position: sticky;
    top: theme('spacing.100');
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: theme('spacing.050');
    background-color: theme('colors.layers.foreground') !important;
    border-radius: theme('card.borderRadius.default');
    padding: theme('spacing.100') theme('spacing.050');
    z-index: theme('zIndex.600');
    box-sizing: border-box;

    @media screen and (min-width: theme('screens.md')) {
        display: none;
    }
}
