.wysiwyg {
    > * + * {
        margin-top: 1em;
    }

    /* --- Table --- */

    :global(.table-wrapper) {
        border: theme('table.border');

        border-radius: theme('table.borderRadius');
        overflow-x: auto;
        scrollbar-color: theme('colors.content.accent') theme('colors.layers.foreground');

        &::-webkit-scrollbar {
            height: 0.5rem;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0 0 0.25rem 0.25rem;
            background-color: theme('colors.content.accent');
        }

        &::-webkit-scrollbar-track {
            border-radius: 0 0 0.25rem 0.25rem;
            background-color: theme('colors.layers.foreground');
        }

        table {
            min-width: 100%;
            width: auto;
        }
    }

    :global(.table) {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        border-radius: theme('table.borderRadius');
        background-color: theme('colors.layers.foreground');
        font-size: theme('fontSize.090');
        text-align: left;

        thead {
            border-bottom: theme('table.border');
            font-weight: 500;
            background-color: theme('table.header.background');
        }

        th {
            white-space: nowrap;
            padding: theme('spacing.100');
            background-color: theme('table.header.background');
            color: theme('colors.neutrals.white');

            + th {
                border-left: theme('table.header.border');
            }
        }

        tr + tr {
            border-top: theme('table.border');
        }

        td {
            padding: theme('spacing.075');
            vertical-align: top;
            white-space: nowrap;

            p {
                white-space: initial;
                min-width: 20rem;
            }

            + td {
                border-left: theme('table.border');
            }

            &:last-child {
                padding-right: theme('spacing.200');
            }
        }

        figure {
            margin: 0;
        }

        .button:only-child {
            width: 100%;
        }

        .trustpilot-widget {
            min-width: 10.75rem;
            padding-top: theme('spacing.025');
            pointer-events: none;
        }
    }

    :global(.table-minimal) {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        text-align: left;

        tbody tr + tr {
            border-top: theme('table.border');
        }

        td {
            width: 50%;
        }

        td + td {
            border-left: theme('table.border');
        }

        tbody td {
            padding: theme('table.cell.padding.large') 0;

            &:last-child {
                padding-left: theme('spacing.100');
            }
        }
    }

    /* --- Elements --- */

    small {
        @apply type-070;
    }

    img {
        max-width: 100%;
    }

    ul,
    ol {

        @apply space-y-050;
        padding: theme('spacing.100') 0 theme('spacing.100') theme('spacing.100');
        list-style: none;
    }

    ol {
        counter-reset: order;
    }

    li {
        padding-left: theme('spacing.150');
        position: relative;
    }

    li::before {
        position: absolute;
        left: 0;
    }

    ol li {
        counter-increment: order;
    }

    ol li::before {
        content: counter(order) '.';
        color: theme('colors.content.foundation');
    }

    ul li::before {
        content: '';
        width: theme('spacing.050');
        height: theme('spacing.050');
        background: theme('colors.layers.soft');
        border-radius: 50%;
        top: calc((calc(16px * 1.6) - 8px) / 2);
    }

    :global(.table-wrapper) {
        margin: theme('spacing.200') 0;
    }

    figure {
        margin: theme('spacing.200') 0;

        &:first-child {
            margin-top: 0;
        }
    }

    figure.outgrow > img {
        position: relative;
        max-width: none;
        width: calc(100% + theme('spacing.200'));
        left: 50%;
        border-radius: 0.25rem;
        transform: translateX(-50%);
        object-fit: cover;
        overflow: hidden;
    }

    :global(.card) {
        display: flex;
        gap: theme('spacing.100');
        padding: theme('card.padding.small');

        img {
            max-width: 4rem;
        }

        :global(.text-title-six) {
            @apply type-100-em;
        }
    }

    /* --- Utilities --- */

    :global(.breathe--large) {
        > * + * {
            margin-left: theme('spacing.100');
        }
    }

    :global(.flow) {
        > * + * {
            margin-top: theme('spacing.050');
        }
    }

    :global(.display--block) {
        display: block;
    }

    :global(.flex) {
        display: flex;
    }

    :global(.flex--row) {
        flex-direction: row;
    }

    :global(.flex--space-between) {
        justify-content: space-between;
    }

    :global(.flex--align-center) {
        align-items: center;
    }

    :global(.flex--wrap) {
        flex-wrap: wrap;
    }

    /* --- Headings --- */

    h1 {

        @apply type-title type-600-em;
    }

    h2 {

        @apply type-title type-500-em;
    }

    h3 {

        @apply type-title type-400-em;
    }

    h4 {

        @apply type-title type-300-em;
    }

    h5 {

        @apply type-subtitle type-100-em;
    }

    h6 {

        @apply type-subtitle type-100-em;
    }

    :global(.text-title-one) {

        @apply type-title type-600-em;
    }

    :global(.text-title-two) {

        @apply type-title type-600-em;
    }

    :global(.text-title-three) {

        @apply type-title type-500-em;
    }

    :global(.text-title-four) {

        @apply type-title type-400-em;
    }

    :global(.text-title-five) {

        @apply type-subtitle type-100-em;
    }

    :global(.text-title-six) {

        @apply type-subtitle type-080-em;
    }

    :global(.font-weight--light) {

        @apply font-300;
    }

    :global(.font-weight--regular) {

        @apply font-400;
    }

    :global(.font-weight--semibold) {

        @apply font-500;
    }

    :global(.font-weight--bold) {

        @apply font-700;
    }

    a:not(:global(.button)) {

        @apply type-link;
        word-wrap: break-word;

        &.treatment-block {
            text-decoration: none;
        }
    }

    /* --- Colours --- */

    :global(.text-color--alpha),
    :global(.text-color--burnt-orange) {
        color: theme('colors.actions.primary');
    }

    :global(.text-color--canvas) {
        color: theme('colors.layers.canvas');
    }

    :global(.text-color--deep-ocean) {
        color: theme('colors.actions.secondary');
    }

    :global(.text-color--shade),
    :global(.text-color--light),
    :global(.text-color--mid),
    :global(.text-color--slate),
    :global(.text-color--washed),
    :global(.text-metadata) {
        color: theme('colors.content.quiet');
    }

    :global(.text-color--metro),
    :global(.text-color--light-blue) {
        color: theme('colors.content.highlight');
    }

    :global(.text-color--midnight) {
        color: theme('colors.content.accent');
    }

    :global(.text-color--success) {
        color: theme('colors.status.success.default');
    }

    :global(.text-color--warning) {
        color: theme('colors.status.warning.default');
    }

    :global(.text-color--error) {
        color: theme('colors.status.error.default');
    }

    :global(.text-color--info) {
        color: theme('colors.status.info.default');
    }

    /* --- Buttons --- */

    :global(.button) {

        @apply button-size-default;
    }

    :global(.button--primary) {

        @apply button-primary;
    }

    :global(.button--secondary) {

        @apply button-secondary;
    }

    :global(.button--default) {

        @apply button-destructive;
    }

    /* --- Treatment Cards --- */

    :global(.treatment-block) {
        display: flex;
        flex-direction: column;
        gap: theme('spacing.100');

        @media screen and (min-width: theme('screens.md')) {
            flex-direction: row;
            align-items: flex-start;

            :global(.treatment-block__image--xs) {
                display: none;
            }

            :global(.treatment-block__image--md) {
                display: block;
            }
        }
    }

    :global(.treatment-block__image) {
        max-width: 4rem;
    }

    :global(.treatment-block__image--xs) {
        display: block;
        margin-right: theme('spacing.100');
    }

    :global(.treatment-block__image--md) {
        display: none;
    }

    :global(.treatment-block__subtitle) {
        margin-bottom: theme('spacing.150');
    }
}
