.wrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: theme('spacing.075');
    align-items: center;
    padding: theme('spacing.050') theme('spacing.050') theme('spacing.050') theme('spacing.125');
    border-radius: 1000px;
    border-width: theme('borderWidth.100');
    border-style: solid;
    overflow: hidden;
    transition: border-color theme('transitionDuration.fast') theme('transitionTimingFunction.default');
    box-shadow: theme('search.searchOverlay.focus.shadow');

    &:focus-within {
        border-color: theme('colors.actions.secondary');
    }

    @supports (container-type: inline-size) {
        flex-wrap: wrap;
    }

    @container (min-width: 320px) {
        flex-wrap: nowrap;
    }
}

.wrapperMobileStack {
    display: flex;
    flex-direction: column;
    padding: theme('padding.050');
    border-radius: theme('borderRadius.200');
    border-width: theme('borderWidth.100');
    border-style: solid;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: border-color theme('transitionDuration.fast') theme('transitionTimingFunction.default');
    
    &:focus-within {
        border-color: theme('colors.actions.secondary');
    }
    
    @container (min-width: 375px) {
        flex-direction: row;
        align-items: center;
        border-radius: 1000px;
        justify-content: space-between;
        padding: theme('spacing.050') theme('spacing.050') theme('spacing.050') theme('spacing.125');
        gap: theme('spacing.075');

        div {
            @apply px-0;
        }
    }
}

.wrapperBordered {
    border-color: theme('colors.decorators.subtle');
    background-color: theme('search.callout.default.background');

    &:focus-within {
        background-color: theme('search.callout.focus.background');
    }
}

.wrapperShadow {
    border-color: theme('colors.neutrals.white');
    background-color: theme('search.searchOverlay.default.background');
    box-shadow: theme('search.searchOverlay.default.shadow');

    &:focus-within {
        background-color: theme('search.searchOverlay.focus.background');
        box-shadow: theme('search.searchOverlay.focus.shadow');
    }
}

.hasButton {
    @container (max-width: 319px) {
        padding: theme('spacing.100') theme('spacing.100') theme('spacing.050');
        border-radius: theme('search.borderRadius');
    }
}

.searchIconRight {
    order: 2;
    padding: theme('search.button.padding');
    box-sizing: content-box;
}

.input {
    @apply type-090;
    flex-grow: 1;
    min-width: 0;
    background-color: transparent; /* Safari adds a grey background colour by default. */
    color: theme('colors.content.quiet');

    &::placeholder {
        color: inherit;
        text-overflow: ellipsis;
    }

    &:placeholder-shown {
        text-overflow: ellipsis;
    }

    &:focus,
    &:focus-visible {
        outline: none;
    }

    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        cursor: pointer;
    }
}

.button {
    @supports (container-type: inline-size) {
        @apply -mx-050;

        width: calc(100% + theme('spacing.100'));
    }

    &:focus,
    &:focus-visible {
        outline: none;
    }

    @container (min-width: 320px) {
        flex-grow: 0;
        flex-shrink: 0;
        width: auto;
        margin: 0;
    }
}

.mobileStackButton {
    @supports (container-type: inline-size) {
        @apply -mx-100;
        width: 100%;
        margin: 0 auto;

    }
    
    &:focus,
    &:focus-visible {
        outline: none;
    }
    
    @container (min-width: 375px) {
        width: calc(100% + theme('spacing.100'));
        flex-grow: 0;
        flex-shrink: 0;
        width: auto;
        margin: 0;
    }
}

