.container {
    @apply container-medium mx-auto container-gutters-small md:container-gutters-medium;
}

.spacing {
    @apply mt-rhythm-medium lg:mt-rhythm-large;

    &:last-child {
        margin-bottom: theme('spacing.rhythm-medium');

        @media (min-width: theme('screens.lg')) {
            margin-bottom: theme('spacing.rhythm-large');
        }
    }
}