.cloud {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: theme('spacing.075');
}

.tag {
    display: inline-block;
    padding: theme('tag.padding.medium');
    --tag-font-size: theme('fontSize.060');
    line-height: theme('lineHeight.none');

    @media (min-width: theme('screens.lg')) {
        padding: theme('tag.padding.large');
        --tag-font-size: theme('fontSize.070');
    }
}
