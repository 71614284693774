.wrapper {
    & > div {
        padding: theme('spacing.075') 0;
        border-bottom: 1px solid theme('colors.decorators.subtle');

        &:last-of-type {
            border-bottom: none;
            padding-top: theme('spacing.100');
            padding-bottom: theme('spacing.100');
        }
    }
}

.flyout {
    padding-top: theme('spacing.500');

    aside {
        border-radius: theme('borderRadius.100') theme('borderRadius.100') 0 0;
    }
}

.flyoutContent {
    ul {
        padding-left: theme('container.gutters.small');
        padding-right: theme('container.gutters.small');
    }

    & > div {
        padding-left: theme('container.gutters.small');
        padding-right: theme('container.gutters.small');
    }
}
