.header {
    position: relative;
    background-color: theme('siteHeader.background');
    padding: theme('siteHeader.padding');
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: theme('siteHeader.background');
    border-radius: 0 0 theme('siteHeader.borderRadius') theme('siteHeader.borderRadius');
    transition: theme('transitionDuration.fast') border-color theme('transitionTimingFunction.default');
    z-index: theme('zIndex.header');
}

.logo {
    flex-shrink: 0;
    border-radius: theme('siteHeaderLink.default.borderRadius');

    &:focus {
        outline: 2px solid theme('colors.layers.strong');
        outline-offset: theme('spacing.0125');
    }

    img {
        width: 7.75rem;
    }
}

.search {
    display: none;

    @media (min-width: theme('screens.lg')) {
        @apply -mr-100;

        display: block;
        flex-grow: 0;
        width: 15rem;
        transition: flex-grow theme('transitionDuration.fast') theme('transitionTimingFunction.default');
    }

    @media (min-width: theme('screens.xl')) {
        width: 19.75rem;
    }

    &:focus-within {
        flex-grow: 1;
    }
}

.links {
    display: flex;
    flex-shrink: 0;
    margin-left: auto;
}

.link {
    gap: theme('siteHeaderLink.spacing');
    padding: theme('siteHeaderLink.padding');
    border-radius: theme('siteHeaderLink.default.borderRadius');
    background: theme('siteHeaderLink.default.background');
    transition: theme('transitionDuration.default') background theme('transitionTimingFunction.default');
    font-family: theme('fontFamily.main');
    font-weight: theme('fontWeight.700');

    &:focus {
        background: theme('siteHeaderLink.hover.background');
    }

    &:focus {
        outline: 2px solid theme('colors.layers.strong');
    }
}
