.section {
    align-self: center;
    max-width: theme('container.sizes.small');
    padding: 0 theme('container.gutters.small');
    margin: theme('rhythm.large') auto;

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
    }
}

.link {
    @apply type-link;

    @media (max-width: 23.5rem) {
        word-break: break-all;
    }
}
