.message {

    @apply type-090 space-y-100;

    h3 {

        @apply type-heading type-090;
    }

    ul {
        list-style: disc;
        padding-left: theme('spacing.150');
    }

    :global(.flow--small) {

        @apply space-y-100;
    }
}
