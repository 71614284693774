/* --- Sizes --- */

.xxsmall {
    width: theme('icon.sizes.xxsmall');
    height: theme('icon.sizes.xxsmall');
}

.xsmall {
    width: theme('icon.sizes.xsmall');
    height: theme('icon.sizes.xsmall');
}

.small {
    width: theme('icon.sizes.small');
    height: theme('icon.sizes.small');
}

.medium {
    width: theme('icon.sizes.medium');
    height: theme('icon.sizes.medium');
}

.large {
    width: theme('icon.sizes.large');
    height: theme('icon.sizes.large');
}

.xlarge {
    width: theme('icon.sizes.xlarge');
    height: theme('icon.sizes.xlarge');
}
