.listItem {
    display: flex;
    align-items: center;
    gap: theme('spacing.075');
    padding: theme('spacing.075') 0;
    border-bottom: 1px dashed theme('colors.decorators.subtle');
}

.img {
    flex-shrink: 0;
    width: theme('icon.sizes.large');
    height: theme('icon.sizes.large');
    padding: theme('spacing.025');
    border-radius: theme('borderRadius.100');
    background: theme('colors.neutrals.white');
    object-fit: contain;
    box-sizing: content-box;
}
