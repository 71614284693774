.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: theme('spacing.050');
    max-width: 21.25rem;
    margin: 0 auto;
}

.separator {
    height: 2px;
    flex-grow: 1;
    background: theme('colors.decorators.subtle');
}

.separatorActive {
    background: theme('colors.content.quiet');
}
