.flash {
    display: flex;
    justify-content: space-between;
    gap: theme('flash.spacing');
    padding: theme('flash.padding');
    border-radius: theme('flash.borderRadius');
}

.tight {
    justify-content: center;
}

.info {
    background: theme('colors.status.info.subtle');

    svg {
        fill: theme('colors.status.info.dark');
    }
}

.warning {
    background: theme('colors.status.warning.subtle');

    svg {
        fill: theme('colors.status.warning.dark');
    }
}

.error {
    background: theme('colors.status.error.subtle');

    svg {
        fill: theme('colors.status.error.dark');
    }
}

.success {
    background: theme('colors.status.success.subtle');

    svg {
        fill: theme('colors.status.success.dark');
    }
}
