.dialog {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100vw;
    height: 100dvh;

    @media (min-width: theme('screens.md')) {
        align-items: center;
    }

}

.panel {
    display: flex;
    flex-direction: column;
    max-width: 38.75rem;
    width: 100%;
    border-radius: 4px 4px 0 0;
    background-color: theme('colors.neutrals.white');
    
    overflow: hidden;
    transition: transform 300ms ease-in-out;

    @media (min-width: theme('screens.md')) {
        max-height: calc(100% - 10.125rem);
        border-radius: theme('borderRadius.200');
    }

    z-index: 0;
}

.panelWindowHeight {
    height: calc(100% - 3.25rem);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: theme('spacing.150') theme('spacing.100');
    border-bottom: 1px solid theme('colors.decorators.subtle');
    flex-grow: 0;
    flex-shrink: 0;
}

.closeButton {

    @apply -m-050;

    padding: theme('spacing.050');
}
