.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.75rem;
    border: 2px solid black;
    border-radius: theme('button.borderRadius');
    background-color: theme('colors.neutrals.black') !important;
    box-sizing: content-box;

    @media screen and (min-width: theme('screens.sm')) {
        width: 12.5rem;
    }
}

.buttonBuy {
    appearance: -apple-pay-button !important;
    -apple-pay-button-type: buy;
    -apple-pay-button-style: black;
}

.buttonLoading {
    :global(.busy-icon) {
        span {
            background: #FFF;
        }
    }
}
