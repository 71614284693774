.confirmationButtons {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.100');
    margin-top: theme('spacing.200');

    @media (min-width: theme('screens.lg')) {
        flex-direction: row-reverse;
        gap: theme('spacing.050');
    }

    a {
        @apply type-link-reverse;

        padding: theme('spacing.125') theme('spacing.300');
        text-align: center;
    }
}
