.questionCard {
    /* We set text right on the panel so that the button aligns right without extra markup and classes. */
    position: relative;
    display: flex;
    flex-direction: column;
    gap: theme('spacing.150');
    width: 100%;
    padding: theme('spacing.150') theme('spacing.100');
    text-align: left;
    transition: opacity 200ms linear;

    @media screen and (min-width: theme('screens.md')) {
        flex-direction: row;
    }

    &.currentStep {
        ~ .questionCard:not(.dirty) {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    textarea {
        min-height: theme('spacing.400');
    }
}

.currentStep {
    opacity: 1;
}

.icon {
    flex-shrink: 0;
    margin-left: auto;
    opacity: 0;
    transition: 0.3s opacity ease-in-out;
}

.dirty {
    opacity: 1;

    .icon {
        opacity: 1;
    }
}

.question {
    @apply type-subtitle type-200 space-y-050 leading-200;

    /* To stop weight being overridden by the wysiwyg. */
    font-weight: theme('fontWeight.500') !important;


    a {
        @apply type-link;
    }
}

.questionDescription {
    @apply type-paragraph space-y-050;

    /* To stop weight being overridden by the wysiwyg. */
    font-weight: theme('fontWeight.400') !important;

    a {
        @apply type-link;
    }
}

.question ul,
.questionDescription ul {
    @apply type-paragraph;
    padding: 0;
    margin: 0;
    list-style-type: disc;
    padding-left: theme('spacing.100');

    li {
        @apply type-090;
        padding-left: theme('spacing.050');
    }

    img {
        margin-top: theme('spacing.050');
    }
}

.providedOn {
    padding: theme('spacing.075');
    background: theme('colors.layers.canvas');
    border-radius: theme('borderRadius.100');
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10) inset;
}
