.treatments {
    @apply grid-cols-2;

    display: grid;
    gap: theme('spacing.075');

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-3;

        gap: theme('spacing.100');
    }
}
