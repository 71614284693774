.card {
    @apply space-y-075 p-[var(--card-padding)];

    container-type: inline-size;
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    box-shadow: none;
    overflow: hidden;
    transition: box-shadow 350ms ease-out;

    &:hover {
        box-shadow: theme('treatmentCards.default.hover.shadow');
        --card-border: theme('treatmentCards.default.default.border');
    }
}

/* --- Children --- */

.header {
    padding: 0 theme('spacing.075');
    padding-top: theme('spacing.075');

    @container (min-width: 215px) {
        padding: 0 theme('spacing.100');
        padding-top: theme('spacing.150');

        h3 {
            font-size: theme('fontSize.300');
            line-height: theme('lineHeight.300');
        }
    }
}

.main {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 theme('spacing.075');
    padding-bottom: theme('spacing.075');

    @container (min-width: 215px) {
        padding: 0 theme('spacing.100');
        padding-bottom: theme('spacing.150');
    }
}

.tagWrapper {
    height:26px;
}

.button {
    display: block;
    width: 100%;
    margin-top: theme('spacing.050');

    @container (min-width: 215px) {
        @apply button-size-default;
    }
}

.link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    /* Need to override tailwind space-y class. */

    @apply opacity-100 outline-none focus:outline-offset-0 focus:outline-content-highlight focus:outline-2 focus:rounded-100;
}

.image {
    display: block;
    margin: 0 auto;
    width: 7.75rem;
    height: 7.75rem;
    object-fit: contain;

    @container (min-width: 215px) {
        width: 10rem;
        height: 10rem;
    }
}

.starRating {
    flex-shrink: 0;

    img {
        @container (max-width: 10.5rem) {
            width: theme('icon.sizes.xxsmall');
            height: theme('icon.sizes.xxsmall');
        }
    }
}

/* Variants */

.specialOffer {
    --card-border: theme('treatmentCards.specialOffer.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.specialOffer.hover.shadow');
        --card-border: theme('treatmentCards.specialOffer.hover.border');
    }
}

.bestSeller {
    --card-border: theme('treatmentCards.bestSeller.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.bestSeller.hover.shadow');
        --card-border: theme('treatmentCards.bestSeller.hover.border');
    }
}

.bundlePack {
    --card-border: theme('treatmentCards.bundlePack.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.bundlePack.hover.shadow');
        --card-border: theme('treatmentCards.bundlePack.hover.border');
    }
}

.newProduct {
    --card-border: theme('treatmentCards.newProduct.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.newProduct.hover.shadow');
        --card-border: theme('treatmentCards.newProduct.hover.border');
    }
}

.multiBuyOffer {
    --card-border: theme('treatmentCards.multiBuy.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.multiBuy.hover.shadow');
        --card-border: theme('treatmentCards.multiBuy.hover.border');
    }
}

.newPackaging {
    --card-border: theme('treatmentCards.newPackaging.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.newPackaging.hover.shadow');
        --card-border: theme('treatmentCards.newPackaging.hover.border');
    }
}

.sale {
    --card-border: theme('treatmentCards.sale.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.sale.hover.shadow');
        --card-border: theme('treatmentCards.sale.hover.border');
    }
}