.card {

    @apply  flex
        flex-col
        w-full
        p-0;
}

.header {

    @apply  flex
        flex-row
        justify-between
        items-center
        w-full
        py-150
        px-100;

    border-bottom: 1px solid theme('colors.decorators.subtle');
}

.content {

    @apply space-y-200;

    padding: theme('spacing.150') theme('spacing.100');
}