.card {
    @apply space-y-100;

    display: flex;
    flex-direction:column;
    justify-content: space-between;
    position: relative;
    height: 100%;
    padding: theme('card.padding.medium');
    box-shadow: none;
    transition: box-shadow 350ms ease-out;
    overflow: hidden;

    &:hover {
        box-shadow: theme('treatmentCards.default.hover.shadow');
        --card-border: theme('treatmentCards.default.default.border');
    }
}

/* Variants */

.specialOffer {
    --card-border: theme('treatmentCards.specialOffer.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.specialOffer.hover.shadow');
        --card-border: theme('treatmentCards.specialOffer.hover.border');
    }
}

.bestSeller {
    --card-border: theme('treatmentCards.bestSeller.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.bestSeller.hover.shadow');
        --card-border: theme('treatmentCards.bestSeller.hover.border');
    }
}

.bundlePack {
    --card-border: theme('treatmentCards.bundlePack.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.bundlePack.hover.shadow');
        --card-border: theme('treatmentCards.bundlePack.hover.border');
    }
}

.newProduct {
    --card-border: theme('treatmentCards.newProduct.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.newProduct.hover.shadow');
        --card-border: theme('treatmentCards.newProduct.hover.border');
    }
}

.multiBuyOffer {
    --card-border: theme('treatmentCards.multiBuy.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.multiBuy.hover.shadow');
        --card-border: theme('treatmentCards.multiBuy.hover.border');
    }
}

.newPackaging {
    --card-border: theme('treatmentCards.newPackaging.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.newPackaging.hover.shadow');
        --card-border: theme('treatmentCards.newPackaging.hover.border');
    }
}

.sale {
    --card-border: theme('treatmentCards.sale.default.border');

    &:hover {
        box-shadow: theme('treatmentCards.sale.hover.shadow');
        --card-border: theme('treatmentCards.sale.hover.border');
    }
}

/* --- Children --- */

.tag {
    position:absolute;
    top: 0;
    left: 0;
    --tag-padding: theme('tag.padding.large');
    --tag-font-size: theme('fontSize.070');
}

.button {
    display: block;
    width: 100%;

    @container (min-width: 215px) {
        @apply button-size-default;
    }
}

.link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    /* Need to override tailwind space-y class. */

    @apply opacity-100 outline-none focus:outline-offset-0 focus:outline-content-highlight focus:outline-2 focus:rounded-100;
}

.image {
    display: block;
    margin: 0 auto;
    width: 7.5rem;
    height: 7.5rem;
    object-fit: contain;
    flex-grow: 0;

    @container (min-width: 215px) {
        width: 8.75rem;
        height: 8.75rem;
    }
}

.featuresList {
    @apply space-y-050;

    flex-grow: 1;
}