.cloud {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: theme('spacing.050');
}

.tag {
    display: flex !important;
    align-items: center;
    gap: theme('spacing.050');
    border-radius: 4px;
}

.button {

    @apply -my-0125 -mx-025;

    padding: theme('spacing.025');
}
