.card {
    position: relative;
    display: flex;
    align-items: center;
    gap: theme('spacing.050');
    justify-content: space-between;
    transition: box-shadow 350ms ease-out;

    &:hover {
        box-shadow: theme('treatmentCards.default.hover.shadow');
    }
}

.link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    font-size: 0;

    &:focus {
        @apply focus:outline-actions-secondary outline-2;
    }
}
