.panel {
    align-self: center;
    max-width: theme('container.sizes.xsmall');
    padding: 0 theme('container.gutters.small');
    margin: theme('spacing.rhythm-xlarge') auto;
    text-align: center;

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
    }
}
