.counter {
    @apply type-070;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: theme('icon.sizes.medium');
    height: theme('icon.sizes.medium');
    padding-bottom: theme('spacing.0125');
    border-radius: 100%;
    background-color: theme('colors.layers.soft');
    color: theme('colors.neutrals.white');
}

.counterActive {
    background-color: theme('colors.layers.strong');
}
