.link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    margin: 0 !important;
}

.description {
    a {
        @apply type-link relative z-20;
    }
}
