.card {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: theme('spacing.050');
}

.link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: theme('card.borderRadius.default');
    font-size: 0;

    &:focus {
        @apply focus:outline-actions-secondary outline-2;
    }
}
