.subTitle {
    @apply md:text-600 md:leading-600 lg:text-700 lg:leading-700;
}

.pmrGrid {

    @apply grid gap-150;

    @screen md {

        @apply grid-cols-2;
    }
}

.pmrGridSupport {
    grid-row: span 2;
    padding-top: 0;

    @screen md {
        grid-column: span 2;
    }

}
