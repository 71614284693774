.banner {
    @apply before-bg-layers-highlight;

    position: relative;
    width: 100%;
    max-height: 0;
    background-color: theme('banner.general.background');
    opacity: 0;
    transition:
        opacity theme('transitionDuration.default') ease-in-out,
        max-height theme('transitionDuration.default') ease-in-out;
    z-index: 450;

    @media (min-width: theme('screens.lg')) {
        z-index: 2;

        &:before {
            content: none;
        }
    }

    svg {
        fill: theme('banner.general.iconColor');
    }
}

.bannerAnimateIn {
    max-height: 5rem;
    opacity: 1;
}

.content {
    @apply container-large;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: theme('spacing.100');
    padding: theme('banner.sizes.small') theme('banner.sizes.medium');
    margin: 0 auto;
    overflow: hidden;

    & > div {
        @apply type-080;

        a {
            @apply type-link;
        }
    }
}

.wysiwyg {
    @apply type-080;

    flex-grow: 1;
    width: 100%;
    color: theme('banner.general.textColor');
    text-align: center;

    a {
        @apply type-090 type-link;

        font-weight: 700;
    }
}
