.card {
    display: flex;
    flex-direction:column;
    gap:theme('spacing.100');
    justify-content:space-between;
    transition: box-shadow 350ms ease-out;

    &:hover {
        box-shadow: theme('treatmentCards.default.hover.shadow');
    }
}

.intro * {

    display:none;

    &:first-child {
        display:-webkit-box;
        overflow:hidden;
        line-clamp: 4;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }   
}