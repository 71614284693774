.item {

    @apply  flex
        justify-start
        items-center
        gap-x-050;
}

.image {

    @apply  w-[theme('icon.sizes.large')]
        h-[theme('icon.sizes.large')];
}

.limit {

    @apply  flex
        flex-row
        items-center
        justify-center
        w-[theme('icon.sizes.large')]
        h-[theme('icon.sizes.large')];

    border: theme('card.bordered.border');
    border-radius: 6px;
    
}