.charityCategory {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    max-width: theme('container.sizes.large');

    @apply mx-auto container-gutters-small;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &:nth-child(even) {

        @media (min-width: 655px) {

            @apply flex-row-reverse;
        }

        .charityImageContainer {
            border-radius: theme('borderRadius.100');
            border-top-right-radius: 96px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            margin-left: -1rem;

            @media (min-width: 655px) {
                border-radius: theme('borderRadius.100');
                border-top-right-radius: 96px;

                @apply mx-0;
            }
        }
    }

    @media (min-width: 655px) {

        @apply container-gutters-medium flex-row;
    }
}

.charityAssets {
    position: relative;
}

.charityBody {
    flex-grow: 1;

    @apply space-y-150;

    @media (min-width: 655px) {
        flex-basis: 70%;

        @apply px-0;
    }
}

.charityImageContainer {
    overflow: hidden;
    border-radius: theme('borderRadius.100');
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-left: theme('container.gutters.small');
    border-top-left-radius: 96px;
    aspect-ratio: 13/8;
    width: 100%;

    @media (min-width: 655px) {
        border-radius: theme('borderRadius.100');
        border-top-left-radius: 96px;

        @apply mx-0;
    }
}
